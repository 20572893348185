import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://74e62a0e46753029cafc96ab65f9b52d@o4506290208243712.ingest.us.sentry.io/4506510861598720",
  debug: false,
  environment: "production",
  release: "soapbox-site@0.39.3",
  tracesSampleRate: 0.5,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()